import React from "react"
import { graphql } from "gatsby"
import { motion } from "framer-motion"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"
import Layout from "../components/layout"
import { Container, Heading } from "../components/styled/Styled"
import { MEDIA_QUERIES, COLORS, formatDate } from "../constants"
import "react-responsive-carousel/lib/styles/carousel.min.css"

const Section = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`

const Article = styled(motion.article)`
  background-color: ${COLORS.GREY};
  padding: 2rem;

  font-size: 1.2rem;

  a {
    color: ${COLORS.SECONDARY};
  }

  blockquote {
    background: ${COLORS.BLACK};
    border-left: 10px solid ${COLORS.SECONDARY};
    margin: 1.5em 10px;
    padding: 0.5em 10px;

    &:before {
      color: ${COLORS.SECONDARY};
      content: open-quote;
      font-size: 4em;
      line-height: 0.1em;
      margin-right: 0.25em;
      vertical-align: -0.4em;
    }
    p {
      display: inline;
    }
  }

  p {
    img {
      display: block;
      max-width: 700px;
    }
  }
`

const ImageContainer = styled(motion.div)`
  width: 100%;
  height: 500px;
  overflow: hidden;
  position: relative;

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    height: 300px;
  }
`

const Img = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

const Date = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  color: ${COLORS.PRIMARY};
  width: 50%;
  font-size: 0.8rem;

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    width: 32.666%;
  }
  @media (max-width: ${MEDIA_QUERIES.SMALL}) {
    width: 100%;
    bottom: auto;
    top: 0;
  }

  p {
    background-color: ${COLORS.GREY};
    padding: 0.5rem;
    border-bottom-right-radius: 5px;
  }
`

const WorkshopTemplate = ({
  data: {
    markdownRemark: { html, frontmatter: workshop },
  },
}) => {
  const start = formatDate(workshop.start)
  const end = formatDate(workshop.end)
  return (
    <Layout>
      <Container>
        <Heading
          initial={{ opacity: 0, x: "-500px" }}
          animate={{
            opacity: 1,
            x: 0,
            transition: { delay: 0.5, duration: 0.75, ease: "easeOut" },
          }}
        >
          {workshop.title}
        </Heading>
        <Section>
          <ImageContainer
            initial={{ opacity: 0, y: 500 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.5, duration: 0.75, ease: "easeOut" },
            }}
          >
            <Img fluid={workshop.bannerimage.childImageSharp.fluid} />
            {workshop.start && (
              <Date>
                <p style={{ marginRight: "auto" }}>
                  {start} {workshop.end && workshop.start && `- ${end}`}
                </p>
              </Date>
            )}
          </ImageContainer>
          <Article
            initial={{ opacity: 0, x: "500px" }}
            animate={{
              opacity: 1,
              x: 0,
              transition: { delay: 1.25, duration: 0.75, ease: "easeOut" },
            }}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </Section>
      </Container>
    </Layout>
  )
}

export default WorkshopTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        start
        end

        bannerimage {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
